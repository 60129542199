<!--
 * @Author: Han
 * @Date: 2021-11-19 15:23:57
 * @LastEditors: Han
 * @LastEditTime: 2021-12-29 18:04:28
 * @FilePath: \HanSharePage\src\components\Content.vue
-->
<template>
  <div class="vvhan-con">
    <div class="vvhan-drama-series">
      <ul>
        <li
          :class="{ active: activeLink == idx }"
          @click="changeVideo(itm,idx)"
          v-for="(itm, idx) in $store.state.VideoLinks"
          :key="idx"
        >
          第{{ idx + 1 }}集
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeLink: 0,
    };
  },
  methods: {
    changeVideo(url,idx) {
      this.activeLink = idx
      this.$store.commit("setVideoUrl", url);
    },
  },
};
</script>

<style lang="less" scoped>
.vvhan-con {
  box-sizing: border-box;
  padding: 0 0 88px;

  .vvhan-drama-series {
    ul {
      display: grid;
      list-style: none;
      grid-template-columns: repeat(8, 1fr);
      grid-row-gap: 8px;
      grid-column-gap: 8px;
      text-align: center;

      li {
        box-sizing: border-box;
        height: 48px;
        line-height: 48px;
        background: #fff;
        border-radius: 8px;
        text-align: center;
        transition: all 0.3s;
        cursor: pointer;

        &:hover {
          background-color: black;
          color: #fff;
        }

        &.active {
          background-color: black;
          color: #fff;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .vvhan-con {
    .vvhan-drama-series {
      ul {
        grid-template-columns: repeat(4, 1fr);
      }
    }
  }
}
</style>